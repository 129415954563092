<template>
  <div class="container details-container">
    <div class="container" v-if="adminType === 'sadmin'">
      <div class="row g-0">
        <div class="card border-0 p-3 col-lg-12 col-xl-8">
          <div class="form" ref="company-details-form">

            <div class="row pb-3">
              <div lg="4" class="col-auto pt-1">
                <ImageSelector class="company-logo" ref="logoImage" :image-src="companyLogoFile" />
              </div>
              <div class="col">
                <div class="row">
                  <div class="col pt-2">
                    
                    <div class=" labels labels-names my-0">
                      <label class="settings-labels"><span class="text-danger">*</span>Name</label>
                      <input type="text" class="form-control form-input"
                        :class="{ 'is-invalid': errors['company.companyName'] }" v-model="companyName"
                        name="companyName" id="companyName" placeholder="Enter your name..." required />
                        <div class="invalid-feedback ps-1" id="name-feedback">{{ errors['company.companyName'] }}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6">
                    <slot name="company-listing-type-selector"></slot>
                  </div>
                </div>
              </div>
            </div>

            <div class="row align-items-end">
              <div class="col-5">
                <div class="mb-3 labels labels-names my-0">
                  <label class="settings-labels">Industry</label>
                  <select class="form-control form-input" v-model="company.industry">
                    <option v-for="(industry, index) of industries" :key="index" :value="industry">{{ industry }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col px-0">
                <slot name="sales-rep"></slot>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <slot name="admin-notes"></slot>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xl-4">
          <slot name="admin-stats"></slot>
        </div>
      </div>
    </div>

    <div class="container" v-if="adminType === 'admin'">
      <div class="card border-0 p-3 col-xl-12">
        <div class="form" ref="company-details-form">
          <div class="row pb-4">
            <div class="col-md-5 col-lg-4 pt-1">
              <ImageSelector class="company-logo" ref="logoImage" :image-src="companyLogoFile" size="sm"
                :up-load="isAdmin" button-location="ul" @change="onLogoChange" />
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="row mb-3 labels labels-names my-0">
                    <label class="settings-labels ms-1"><span class="text-danger">*</span>Name</label>
                    <input type="text" class="form-control form-input mx-1"
                      :class="{ 'is-invalid': errors['company.companyName'] }" v-model="companyName" name="companyName"
                      id="companyName" placeholder="Enter your name..." >
                    <div class="invalid-feedback ps-1" id="name-feedback">{{
                      errors['company.companyName'] }}</div>
                  </div>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col">
                  <div class="row mb-3 labels labels-names my-0">
                    <label class="settings-labels ms-2">Industry</label>
                    <select class="form-control form-input mx-1" v-model="company.industry">
                      <option v-for="(industry, index) of industries" :key="index" :value="industry">{{
                        industry }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <h5 class="mb-0">
                <span class="text-danger">*</span>Contact Information
              </h5>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <div class="row mb-3 labels labels-names my-0">
                <label class="settings-labels ms-2">Website</label>
                <input type="text" class="form-control form-input mx-1" :class="{ 'is-invalid': errors['company.url'] }"
                  v-model="companyUrl" name="companyUrl" id="companyUrl" placeholder="Enter your website...">
                <div class="invalid-feedback ps-1" id="name-feedback">{{
                  errors['company.url'] }}</div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-lg-8 me-2">
              <div class="row mb-3 labels labels-names my-0">
                <label class="settings-labels ms-2">Email</label>
                <input type="text" class="form-control form-input mx-1"
                  :class="{ 'is-invalid': errors['company.email'] }" v-model="companyEmail" name="companyEmail"
                  id="companyEmail" placeholder="Enter your email...">
                <div class="invalid-feedback ps-1" id="name-feedback">{{
                  errors['company.email'] }}</div>
              </div>
            </div>

            <div class="col">
              <div class="row mb-3 labels labels-names my-0">
                <label class="settings-labels ms-2">Phone</label>
                <input type="text" class="form-control form-input me-1"
                  :class="{ 'is-invalid': errors['company.telephone'] }" v-model="companyTelephone"
                  name="companyTelephone" id="companyTelephone" placeholder="xxx-xxx-xxxx">
                <div class="invalid-feedback ps-1" id="name-feedback">{{
                  errors['company.telephone'] }}</div>
              </div>
            </div>
          </div>


          <div class="row mt-4">
            <div class="col">
              <h5 class="mb-0">
                <span class="text-danger">*</span>Address
              </h5>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-lg-8 me-2">
              <div class="row mb-3 labels labels-names my-0">
                <label class="settings-labels ms-2">Street Address</label>
                <input type="text" class="form-control form-input mx-1"
                  :class="{ 'is-invalid': errors['company.streetAddress'] }" v-model="companyStreetAddress"
                  name="companyStreetAddress" id="companyStreetAddress" placeholder="Enter your street address...">
                <div class="invalid-feedback ps-1" id="name-feedback">{{
                  errors['company.streetAddress'] }}</div>
              </div>
            </div>

            <div class="col me-2">
              <div class="row mb-3 labels labels-names my-0">
                <label class="settings-labels ms-2">Country</label>
                <country-select id="country" v-model="company.country" :country="country" topCountry="US"
                  class="form-control" removePlaceholder />
              </div>
            </div>

            <div class="col-lg-2 me-1 ">
              <div class="row mb-3 labels labels-names my-0">
                <label class="settings-labels ms-2">State</label>
                <region-select id="state" v-model="companyRegion" :country="country" :region="region"
                  class="form-control" autocomplete="address-level1" />
                <small class="form-text text-danger">{{
                  errors['company.state'] }}</small>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-lg-8 me-2">
              <div class="row mb-3 labels labels-names my-0">
                <label class="settings-labels ms-2">City</label>
                <input type="text" class="form-control form-input ms-1" v-model="company.city" name="companyCity"
                  id="companyCity" placeholder="Enter your city...">
              </div>
            </div>

            <div class="col me-1">
              <div class="row mb-3 labels labels-names my-0">
                <label class="settings-labels ms-2">Zip Code</label>
                <input type="text" class="form-control form-input" v-model="company.postcode" name="companyPostcode"
                  id="companyPostcode" placeholder="xxxxx">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageSelector from "../shared/ImageSelector.vue";
import { fetchIndustries } from "../../services/IndustriesService";
import { uploadFile } from "../../services/FileService";
import Industry from "../../models/Industry";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { inject, watch } from "vue";
import { useForm } from "vee-validate";
import { toTypedSchema } from '@vee-validate/yup';
import { object, string } from "yup";


const logoImage = ref<typeof ImageSelector>();

const props = defineProps<{
  adminType: string;
  isSubmitting?: boolean;
}>();

watch(
  () => props.isSubmitting,
  (currentValue, previousValue) => {
    if (currentValue === true) {
      checkFormValidity();
    }
  }
);

const $store = useStore();
const emitter: any = inject("emitter");


const company = computed(() => {
  return $store.getters.company;
});
const published = computed(() => {
  const published = company.value.published;
  return published ? "Yes" : "No";
});
const companyLogoFile = computed(() => {
  if (company.value.logoFile) {
    return company.value.logoFile.fileUrl;
  }
  return "";
});
const companyStats = computed(() => {
  return $store.getters.stats;
});
const isAdmin = computed(() => {
  return props.adminType === "admin" ? true : false;
});
const user = computed(() => {
  return $store.getters.authUser;
});

const schema = object({
  company: object({
    companyName: string().required("Company name is a required field"),
    url: string().required("Company website is a required field").url("Please provide a valid URL"),
    email: string().required("Company email is a required field").email("Please provide a valid email"),
    telephone: string().required("Company telephone is a required field").trim(),
    streetAddress: string().required("Company street address is a required field").trim(),
    state: string().required("Company region/state is a required field").notOneOf(["Select Region"]),
  }),
})
let { validate, errors, defineField, setErrors, defineInputBinds } = useForm({ validationSchema: toTypedSchema(schema) });
const [companyName] = defineField("company.companyName");
const [companyUrl] = defineField("company.url");
const [companyEmail] = defineField("company.email");
const [companyTelephone] = defineField("company.telephone");
const [companyStreetAddress] = defineField("company.streetAddress");
const [companyRegion] = defineField("company.state");

let industries = ref<Industry[]>([]);
let logoFile: File | null = null;

const adminNotes = "This is a notes example";
const country = "US";
const region = "CO";

onMounted(async () => {
  industries.value = await fetchIndustries();
  companyName.value = company.value.companyName;
  console.log(companyName.value, "companyName.value 1");
  companyUrl.value = company.value.url;
  companyEmail.value = company.value.email;
  companyTelephone.value = company.value.telephone ? company.value.telephone : "";
  companyStreetAddress.value = company.value.streetAddress
  companyRegion.value = company.value.state
});

async function submit() {
  if (logoFile) {
    const file = await uploadFile(logoFile);
    company.value.logoFile = file;
  }
}

function adminNameChange() {
  emitter.emit("adminNameChange", companyName.value);
}

async function checkFormValidity() {
  console.log("checkFormValidity");
  const { valid, errors } = await validate();
  if(valid){
    company.value.companyName = companyName.value;
    company.value.url = companyUrl.value;
    company.value.email = companyEmail.value;
    company.value.telephone = companyTelephone.value;
    company.value.streetAddress = companyStreetAddress.value;
    company.value.state = companyRegion.value;
    $store.commit("setCompany", company.value);
  }
  emitter.emit("submitCompanyDetails", valid);
}

function onLogoChange() {
  if (logoImage.value?.imageFile) {
    logoFile = logoImage.value.imageFile;
    $store.commit("setCompanyLogoFile", company.value.logoFile);
    submit();
  }
}
</script>

<style scoped>
.company-logo {
  width: 150px;
  height: 150px;
}

.settings-labels {
  margin-bottom: 0.25rem;
}

.labels-names {
  font-size: 16px !important;
}

.details-container {
  overflow: auto;
  height: 500px;
  margin-bottom: 32px;
}

.form-input {
  border-radius: 8px;
}

.labels {
  font-size: 18px;
}

.stats-header {
  font-family: "proximanova-medium", sans-serif;
  font-size: 16px;
}

.stats-label {
  font-family: "proximanova-bold", sans-serif;
  font-size: 16px;
}

.stats-body {
  background-color: #e5e5e5;
  border-radius: 0.5rem;
}

:deep .btn:focus,
.btn.focus {
  box-shadow: none;
}

.footer-button {
  height: 30px;
  font-size: 16px;
  line-height: 14px;
}

@media screen and (max-width: 576px) {
  .details-container {
    overflow: auto;
    height: auto;
    margin-bottom: 0rem;
  }
}
</style>