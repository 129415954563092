<template>
  <div class="card basic-card">
    <div class="row">
      <div class="col-7">
        <p class="subscription-header text-center">Basic</p>
      </div>
    </div>

    <div v-if="modalType === 'billing'" class="row" style="margin-bottom: -1.5rem;">
      <div class="col">
        <span class="dollar">$</span>
        <span class="subscription-price">{{ basic.price }}</span>
        <span class="subscription-price-text">/ {{ $filters.capitalizeFirst(basic.interval as string) }}</span>
      </div>
    </div>

    <div v-if="modalType !== 'billing'" class="row ps-4 pt-2">
      <div class="col">
        <p class="plan-description">For brands looking to make an impact</p>
      </div>
    </div>

    <div class="row ps-3 py-2">
      <div class="col">
        <ul class="list-group" style="line-height: 28px;">
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Basic Company Categories*</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">1 Product Experience</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Product Real Code</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">3 Sections per Product</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">External Links for CTAs</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Cloud Hosting</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Full Real App Integration &amp; Updates</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Basic Analytics</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row px-3 pb-3 align-items-end align-self-center row-bottom" v-if="showSubmit">
      <div class="col">
        <payment-choices :subscription-type="basic"></payment-choices>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import Plan from "../../../models/Plan";
import PaymentChoices from "../PaymentChoices.vue";
import { useStore } from "vuex";

const props = defineProps<{
  planDetails?: Plan;
  modalType?: string;
  showSubmit?: boolean;
}>();

const store = useStore();

const plans = computed(() => store.getters.plans);
const basic = ref<Partial<Plan>>({});

onMounted(() => {
  basic.value = plans.value.filter((plan: Plan) => plan.name === "basic")[0];
});
</script>

<style scoped>
.icon{
  /* color: #3679e8; */
  font-size: 1.5rem;
}

.icon-container{
  display: flex;
  align-items: center;
}

.plan-card {
  border-radius: 0.75rem;
}

.plan-header {
  font-size: 32px;
  font-family: "proximanova-medium", sans-serif;
}

.card {
  border: 2px solid #898989;
  border-radius: 0.75rem;
}

@media (min-width: 1200px) {
  .basic-card {
    height: 100%;
  }
}

@media (max-width: 991px) {
  .basic-card {
    height: 100%;
  }
}

.card-body {
  padding-top: 0px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
}

.list-group-item {
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 0.5rem;
}

.list-group {
  font-family: "proximanova-medium", sans-serif;
  font-size: 16px;
}

.row-bottom {
  flex: 1 0 0;
}

.subscription-price {
  font-size: 64px;
  font-family: "proximanova-medium", sans-serif;
  padding-right: 0px;
  padding-left: 3.5rem;
}

.plan-description {
  font-size: 16px;
  font-family: "proximanova-medium", sans-serif;
}

.dollar {
  font-size: 2rem;
  vertical-align: top;
  position: absolute;
  top: 4rem;
  padding-left: 2rem;
}

.subscription-price-text {
  font-size: 20px;
  font-family: "proximanova-regular", sans-serif;
}

.subscription-header {
  font-family: "proximanova-regular", sans-serif;
  font-size: 22px;
  background-color: #d5f9de;
  color: #074f57;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
</style>
