<template>
  <authentic-modal ref="companyUsersModal" id="company-users-modal" size="lg" :title="modalTitle" :hide-footer="true">
    <company-users-list :fields="fields" action-title="Add new user" :has-search="true">
    </company-users-list>
  </authentic-modal>
</template>

<script setup lang="ts">
  import AuthenticModal from "../shared/AuthenticModal.vue";
  import CompanyUsersList from "./CompanyUsersList.vue";
  import { onBeforeMount, ref, inject, onBeforeUnmount } from "vue";

  const companyUsersModal = ref<typeof AuthenticModal>();

  const emitter: any = inject("emitter");

  const modalTitle = "User management";

  const fields = [
    { key: "name", tdClass: "align-middle" },
    { key: "email", class: "limit-column-width", tdClass: "align-middle" },
    { key: "role", tdClass: "align-middle" },
    {
      key: "status",
      tdClass: ["align-middle", "text-center"],
      thClass: "text-center",
    },
    { key: "action", label: "", tdClass: "align-middle" },
  ];

  onBeforeMount(() => {
    emitter.on("user-management", async () => {
      companyUsersModal.value?.showModal();
    });
  });

  onBeforeUnmount(() => {
    emitter.off("user-management");
  });

</script>

<style scoped></style>
