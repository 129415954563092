<template>
  <authentic-modal ref="subscriptionModal" id="subscription-modal" size="xl" :hideFooter="true" title="">
    <div class="conntainer select-payment">
      <div class="card border-0">
        <p class="card-text subscription-title mb-0">Welcome to your Real&reg; Brand Dashboard account!</p>
        <p class="card-text subscription-subtitle">Please choose a plan below to begin editing and previewing your
          content.</p>
      </div>
      <div class="row choose">
        <RealPlanGroup />
      </div>
      <div class="row">
        <div class="col">
          <p class="card-text text-muted ps-4 footnotes">* Basic company categories: Environment, Manufacturing,
            Community,
            Diversity & Equity, Ingredients, Data Privacy and Accessibility.
          </p>
          <p class="card-text text-muted ps-4 footnotes">** Premium categories: Social Media Connect, Authorized
            Retailers,
            Promo Codes & Discounts, Careers & Recruitment, Product
            Registration, Topical, Leadership, News, and more.</p>
          <p class="card-text text-muted ps-4 footnotes">*** Serialized codes for product authentication available on
            the
            Pro and Business plans at an additional, but extremely reasonable,
            cost.</p>
        </div>
      </div>
    </div>
  </authentic-modal>
</template>

<script setup lang="ts">
  import { ref, inject, onBeforeMount, onBeforeUnmount } from "vue";
  import AuthenticModal from "./AuthenticModal.vue";
  import RealPlanGroup from "./RealPlanGroup.vue";

  const subscriptionModal = ref<typeof AuthenticModal | null>(null);
  const emitter: any = inject("emitter");

  const modalTitle = "Select a plan";

  onBeforeMount(() => {
    emitter.on("subscription-modal", () => {
      subscriptionModal.value!.showModal();
    });
    emitter.on("subscription-modal-close", () => {
      subscriptionModal.value!.hideModal();
    });
  });

  onBeforeUnmount(() => {
    emitter.off("subscription-modal");
    emitter.off("subscription-modal-close");
  });
</script>

<style scoped>
.subscription-title {
  font-family: "proximanova-medium", sans-serif;
  font-size: 32px;
}

.subscription-subtitle {
  font-size: 16px;
  margin-bottom: -20px !important;
}

.footnotes {
  margin-bottom: 5px;
  font-size: 12px;
}

.choose {
  margin-bottom: -20px !important;
}

.select-payment {
  height: 80vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
</style>
