<template>
  <authentic-modal ref="userProfileModal" id="user-profile-modal" :on-submit="submit" size="lg" :title="title">
    <form ref="user-profile-form" class="p-4">
      <div class="row pb-4">
        <div class="col-md-5 col-lg-3">
          <AvatarSelector />
        </div>

        <div class="col d-flex flex-column">
          <div class="d-flex justify-content-end">
            <a @click="forgotPasswordLink" class="reset">Reset your password?</a>
          </div>

          <div class="form-group labels labels-names my-0">
            <label for="name">
              <span class="text-danger">*</span>Name
            </label>
            <input v-model="(name.value.value as string)" id="name" type="text" class="form-control form-input"
              placeholder="Enter your name..." required :class="{ 'is-invalid': name.errorMessage.value }" />
            <div v-if="name.errorMessage.value" class="invalid-feedback">
              {{ name.errorMessage.value }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h5>Contact Information</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-8">
          <div class="form-group labels">
            <label for="email">
              <span class="text-danger">*</span>Email
            </label>
            <input v-model="(userEmail.value.value as string)" id="email" type="email" class="form-control form-input"
              placeholder="Enter your email..." required :class="{ 'is-invalid': userEmail.errorMessage.value }" />
            <div v-if="userEmail.errorMessage.value" class="invalid-feedback">
              {{ userEmail.errorMessage.value }}
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-group labels">
            <label for="phonenumber">
              <span class="text-danger">*</span>Phone
            </label>
            <input v-model="(userPhone.value.value as string)" id="phonenumber" type="text"
              class="form-control form-input" placeholder="xxx-xxx-xxxx"
              :class="{ 'is-invalid': userPhone.errorMessage.value }" />
            <div v-if="userPhone.errorMessage.value" class="invalid-feedback">
              {{ userPhone.errorMessage.value }}
            </div>
          </div>
        </div>
      </div>
    </form>

    <template #modal-footer>
      <div class="btn-group pe-4">
        <button type="button" class="btn btn-outline-secondary btn-sm rounded-pill ms-2"
          @click.prevent="cancel">Cancel</button>
        <button type="button" class="btn btn-primary btn-sm rounded-pill ms-2" :disabled="!meta.valid"
          @click.prevent="submit">
          Save
        </button>
      </div>
    </template>
  </authentic-modal>
</template>

<script setup lang="ts">
import AvatarSelector from "../shared/AvatarSelector.vue";
import User from "../../models/User";
import { profile } from "../../services/UserService";
import AuthenticModal from "../shared/AuthenticModal.vue";
import { useField, useForm } from "vee-validate";
import { object, string } from "yup";
import { ref as yupRef } from "yup";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { computed, inject, markRaw, onMounted, ref } from "vue";
import { useToast } from "vue-toastification";

const emitter: any = inject("emitter");
const $store = useStore();
const router = useRouter();
const route = useRoute();
const toast = useToast();

const userProfileModal = ref<typeof AuthenticModal>();

const user = computed(() => {
  return $store.getters.authUser;
});

const validationSchema = markRaw(
  object({
    name: string().required("name is a required field"),
    userPhone: string().required("user phone is required"),
    userEmail: string()
      .email("please provide a valid email")
      .required("user email is required")
      .trim(),
  })
);
const { validate, errors, meta } = useForm({ validationSchema });
const name = useField("name", validationSchema);
const userPhone = useField("userPhone", validationSchema);
const userEmail = useField("userEmail", validationSchema);

const title = "User Profile";

onMounted(() => {
  emitter.on("user-profile", () => {
    name.value.value = user.value.name;
    userEmail.value.value = user.value.email;
    userPhone.value.value = user.value.phone;
    userProfileModal.value?.showModal();
  });
});

function cancel() {
  userProfileModal.value?.hideModal();
}

function forgotPasswordLink() {
  userProfileModal.value?.hideModal();
  router.replace({ name: "forgotPassword" });
}

async function submit() {
  const isValid = await validate();
  if (!isValid.valid) {
    return;
  }
  try {
    user.value.name = name.value.value as string;
    user.value.email = userEmail.value.value as string;
    user.value.phone = userPhone.value.value as string;
    await profile(user.value.id!, user.value);
    $store.commit("authUser", user.value);
    userProfileModal.value?.hideModal();
    toast.success("User profile updated.");
  } catch (error) {
    toast.error("An error occurred when updating your user profile!");
  }
}
</script>

<style scoped>
.profile-avatar {
  border: 0;
  border-radius: 10px;
  width: 150px;
}

.labels-names {
  font-size: 16px !important;
}

.form-input {
  border-radius: 8px;
}

.labels {
  font-size: 18px;
}

:deep .btn:focus,
.btn.focus {
  box-shadow: none;
}

.footer-button {
  height: 30px;
  font-size: 16px;
  line-height: 14px;
}

.reset {
  color: red;
}

</style>
