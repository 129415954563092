<template>
  <div class="card business-card">
    <div class="row">
      <div class="col-7">
        <p class="subscription-header text-center">Business</p>
      </div>
    </div>

    <div v-if="props.modalType === 'billing'" class="row" style="margin-bottom: -1.5rem;">
      <div class="col">
        <span class="dollar">$</span>
        <span class="subscription-price">{{ business.price }}</span>
        <span class="subscription-price-text">/ {{ $filters.capitalizeFirst(business.interval as string) }}</span>
      </div>
    </div>

    <div v-if="props.modalType !== 'billing'" class="row ps-4 pt-2">
      <div class="col">
        <p class="plan-description">
          For organizations that require more dedicated product experiences and support
        </p>
      </div>
    </div>

    <div v-if="props.presentation === 'two-column'" class="row ps-2 pt-2 pb-4">
      <div class="col">
        <ul class="list-group" style="line-height: 28px;">
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Everything included in Basic and Pro Plus...</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Premium Company Categories**</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">7 Product Experiences and Codes</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Unlimited Product Sections</span>
          </li>
        </ul>
      </div>
      <div class="col">
        <ul class="list-group" style="line-height: 28px;">
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Product Authentication***</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Detailed Product Analysis</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">30-Minute Strategy Kick-off Call</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Dedicated Success Manager</span>
          </li>
        </ul>
      </div>
    </div>

    <div v-else class="row ps-2 pt-2 pb-4">
      <div class="col">
        <ul class="list-group" style="line-height: 28px;">
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Everything included in Pro Plus...</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Premium Company Categories**</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">7 Product Experiences and Codes</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Unlimited Product Sections</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Product Authentication***</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Detailed Product Analysis</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">30-Minute Strategy Kick-off Call</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Dedicated Success Manager</span>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="props.showSubmit" class="row px-3 pb-3 align-items-end align-self-center row-bottom">
      <div class="col">
        <payment-choices :subscription-type="(business as any)" :upgrade="props.upgrade"></payment-choices>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { defineProps } from "vue";
import Plan from "../../../models/Plan";
import PaymentChoices from "../PaymentChoices.vue";
import { useStore } from "vuex";

const props = defineProps<{
  planDetails?: Plan;
  showSubmit?: boolean;
  modalType?: string;
  presentation?: string;
  upgrade?: boolean;
}>();

const store = useStore();

const plans = computed(() => store.getters.plans);
const business = ref<Partial<Plan>>({});

onMounted(() => {
  business.value = plans.value.filter((plan: { name: string; }) => plan.name === "business")[0];
});
</script>

<style scoped>
.icon{
  /* color: #3679e8; */
  font-size: 1.5rem;
}

.icon-container{
  display: flex;
  align-items: center;
}

.plan-card {
  border-radius: 0.75rem;
}

.plan-header {
  font-size: 32px;
  font-family: "proximanova-medium", sans-serif;
}

.card {
  border: 2px solid #898989;
  border-radius: 0.75rem;
}

@media (min-width: 1200px) {
  .business-card {
    height: 100%;
  }
}

@media (max-width: 991px) {
  .business-card {
    height: 100%;
  }
}

.card-body {
  padding-top: 0px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
}

.list-group-item {
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 0.5rem;
}

.list-group {
  font-family: "proximanova-medium", sans-serif;
  font-size: 16px;
}

.row-bottom {
  flex: 1 0 0;
}

.subscription-price {
  font-size: 64px;
  font-family: "proximanova-medium", sans-serif;
  padding-right: 0px;
  padding-left: 3.5rem;
}

.plan-description {
  font-size: 16px;
  font-family: "proximanova-medium", sans-serif;
}

.dollar {
  font-size: 2rem;
  vertical-align: top;
  position: absolute;
  top: 4rem;
  padding-left: 2rem;
}

.subscription-price-text {
  font-size: 20px;
  font-family: "proximanova-regular", sans-serif;
}

.subscription-header {
  font-family: "proximanova-regular", sans-serif;
  font-size: 22px;
  background-color: #074f57;
  color: white;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
</style>
