<template>
  <div class="container pb-4 px-4">
    <div class="row g-0" no-gutters>
      <div class="col">
        <h5 class="current">Add a new card</h5>
      </div>
    </div>
    <billing-details :on-cancel="summary" :card-action="'save'" :newCard="true">
      <template v-slot:billing-title>
        <p class="card-text mb-2"></p>
      </template>
    </billing-details>
  </div>
</template>

<script setup lang="ts">
  import BillingDetails from "../shared/BillingDetails.vue";
  import { inject } from "vue";

  const emitter: any = inject("emitter");

  function summary() {
    emitter.emit("billing-summary");
  }
</script>

<style scoped>
.go-summary {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
}

.pay-card {
  font-family: "proximanova-medium", sans-serif;
  font-size: 1px;
}

h5 {
  font-family: "proximanova-medium", sans-serif !important;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}
</style>
