<template>
  <div class="card plan-card border-0">
    <div class="card-header text-center bg-transparent border-0 plan-header">
      Enterprise
    </div>
    <div class="card-body">
      <p class="card-text">
        Enterprise plan here. CSR sections. No premium (pro) content.
        {{ props.planDetails!.productNumber }} product,
        {{ props.planDetails!.productSections }} sections
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { defineProps } from "vue";
  import Plan from "../../../models/Plan";

  const props = defineProps<{
    planDetails?: Plan;
  }>();
</script>

<style scoped>
.plan-card {
  border-radius: 0.75rem;
}

.plan-header {
  font-size: 32px;
  font-family: "proximanova-medium", sans-serif;
}
</style>
