<template>
  <div class="container-fluid d-flex h-100 flex-column">
    <div class="row g-0">
      <div class="col">
        <h5 class="current">Current plan</h5>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <div class="row g-0">
          <p class="card-text subscription-plan">{{
            billingDetails.subscriptionName
            }}
          </p>
          <div class="row g-0 pb-0">
            <p class="card-text subscription-price">${{ billingDetails.planPrice }} /
              {{ billingDetails.paymentInterval }}</p>
          </div>
          <div class="row g-0">
            <p class="card-text subscription-renewal">Number of included products available on your current plan:
              {{ billingDetails.productNumber }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="card-text">You can add additional products to your current plan for $20 / month
          </p>
        </div>
      </div>

      <div class="row pt-3 current">
        <div class="col">
          <label for="demo-sb">Additional products (shows current number of added products).</label>
          <div class="spinner form-control d-flex align-items-stretch" role="group" tabindex="-1">
            <button class="btn btn-sm border-0 rounded-0 py-0 spinner-button" tabindex="-1" type="button" @click="updateProductNumbers('dec')">
              <i class="bi bi-dash" style="font-size: 1.5em;"></i>
            </button>
            <output class="flex-grow-1 align-self-center border-left border-right" dir="ltr" id="demo-sb"
              role="spinbutton" tabindex="0"><bdi>{{ productNumber }}</bdi>
            </output>
            <button class="btn btn-sm border-0 rounded-0 py-0 spinner-button" tabindex="-1" type="button" @click="updateProductNumbers('inc')">
              <i class="bi bi-plus" style="font-size: 1.5em;"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="pe-0 col-7">
          <p class="card-text current-payment" v-if="showUpdatedPricing">What you will pay {{ freq }} starting
            {{ billingDetails.renewalDate }}</p>
          <p class="card-text current-payment" v-else>What you will pay {{ freq }}</p>
        </div>
        <div class="col">
          <div class="row">
            <div class="pe-2 text-end col-3">${{ updatedPrice }}</div>
          </div>

          <div class="row">
            <div class="col-6 text-muted default-payment">
              <span class="pe-1">{{
                $filters.capitalizeFirst(defaultPayment.brand)
                }}</span>
              <span class="pe-0"><span>&bull;&bull;&bull;&bull;</span>
                {{ defaultPayment.last_four }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row flex-grow-1 align-items-end text-end row-bottom pb-3 pe-0">
        <div class="col pe-0 mt-3 align-right">
          <div class="btn-group" role="group">
            <button class="btn btn-outline-secondary btn-sm rounded-pill ms-2 action-button" type="button"
              @click="summary">Go back</button>
            <button ref="updateQuantityButton" class="btn btn-sm rounded-pill ms-2 action-button" :class="confirmButton" type="button"
              @click="confirmQuantityUpdate" :disabled="disableSubmit">Confirm changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Billing from "../../models/Billing";
  import { updateAdditionalProducts } from "@/services/PaymentService";
  import { createProduct } from "../../services/ProductService";
  import { uploadFile } from "../../services/FileService";
  import { Product } from "@authentic-labs/vue-component-library";
  import { useToast } from "vue-toastification";
  import { useStore } from 'vuex'
  import { computed, inject, onMounted, ref } from "vue";

  const updateQuantityButton = ref<HTMLButtonElement>()

  const props = defineProps<{
    billingDetails: Billing,
    unarchive?: boolean,
  }>();

  const $store = useStore();
  const toast = useToast();
  const emitter: any = inject("emitter");


  const products = computed(() => {
    return $store.getters.products;
  });

  const user = computed(() => {
    return $store.getters.authUser;
  })

  const freq = computed((): string => {
    if (props.billingDetails.paymentInterval === "year") {
      return "yearly";
    }
    return "monthly";
  });

  const updatedPrice = computed((): string => {
    return updatedPricing.value;
  });

  const defaultPayment = computed(() => {
    let paymentMethod: any = [];
    const found = props.billingDetails.paymentMethods!.find((element: any) => {
      element.default === true;
      paymentMethod = element;
    });
    return paymentMethod;
  });

  const placeHolderHeaderImage = computed((): string => {
    return new URL("/src/assets/images/app/placeholder16x9.png", import.meta.url).href;
  });

  const placeHolderLogoImage = computed((): string => {
    return new URL("/src/assets/images/app/placeholder1x1.png", import.meta.url).href;
  });

  const confirmedButtonHTML = `<div class="confirmed-text">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                  </svg> <span class="confirmed-text-span">Changes confirmed</span>
                </div>`;

  const showUpdatedPricing = ref(false);
  function summary() {
    emitter.emit("billing-summary");
  }

  const perProductCost = ref(20);
  const productNumber = ref(0);
  const updatedPricing = ref("");
  const disableSubmit = ref(false);
  const confirmButton = ref("btn-primary");
  const priceId = ref("");
  const productQuantity = ref(0);
  const payLoad = ref<Partial<Product>>({});

  onMounted(() => {
    productNumber.value = props.billingDetails.additionalProducts;
    updatedPricing.value = props.billingDetails.planPrice;
  });

  function setPriceId() {
    props.billingDetails.paymentInterval === "year"
      ? (priceId.value = import.meta.env.VITE_APP_PRO_PRODUCT_YEARLY)
      : (priceId.value = import.meta.env.VITE_APP_ADDITIONAL_PRODUCT);
  }

  function updateProductNumbers(direction: string) {
    if(direction === "inc" && productNumber.value < 100){
      productNumber.value++;
    }
    if(direction === "dec" && productNumber.value > 0){
      productNumber.value--;
    }
    if (props.billingDetails.additionalProducts !== productNumber.value) {
      updatedPricing.value = (
        parseInt(props.billingDetails.planPrice) +
        (productNumber.value - props.billingDetails.additionalProducts) * perProductCost.value
      ).toString();
      productQuantity.value = productNumber.value - props.billingDetails.additionalProducts;
      showUpdatedPricing.value = true;
    } else {
      showUpdatedPricing.value = false;
      updatedPricing.value = props.billingDetails.planPrice;
    }
  }

  async function confirmQuantityUpdate() {
    setPriceId();
    let subscribeResponse = "";
    disableSubmit.value = true;
    if (props.billingDetails.paymentMethods) {
      const paymentDetails = {
        paymentMethod: defaultPayment.value.id,
        priceId: priceId.value,
        quantity: productQuantity.value,
        subscriptionType: props.billingDetails.subscriptionName,
        currentAdditionalProducts: props.billingDetails.additionalProducts,
      };
      subscribeResponse = await updateAdditionalProducts(paymentDetails);
      $store.dispatch("getSubscriptionStatus", { user: user.value });
      if (subscribeResponse === "failed") {
        paymentResponse(subscribeResponse);
        productNumber.value = props.billingDetails.additionalProducts;
        disableSubmit.value = false;
        return;
      } else {
        if (props.unarchive) {
          emitter.emit("billing-summary", "refresh");
          emitter.emit("billing-modal-close");
          emitter.emit("from-billing-unarchive");
        } else {
          createNewProduct(productQuantity.value);
          updateQuantityButton.value!.innerHTML = confirmedButtonHTML;
          confirmButton.value = "btn-success";
          emitter.emit("billing-summary", "refresh");
        }
      }
    }
    paymentResponse(subscribeResponse);
  }

  async function setPlaceholderImages() {
    const headerResponse = await fetch(placeHolderHeaderImage.value);
    const logoResponse = await fetch(placeHolderLogoImage.value);
    const headerBlob = await headerResponse.blob();
    const logoBlob = await logoResponse.blob();
    const headerFile = new File([headerBlob], "placeholder16x9.png", {
      type: headerBlob.type,
    });
    const logoFile = new File([logoBlob], "placeholder1x1.png", {
      type: logoBlob.type,
    });
    const fileHeader = await uploadFile(headerFile);
    const fileLogo = await uploadFile(logoFile);
    return { fileHeaderId: fileHeader.filesId, fileLogoId: fileLogo.filesId };
  }

  async function createNewProduct(quantity: number) {
    payLoad.value = {
      model: "",
      name: "Product name",
      status: "active",
    };
    const placeholderResponse = await setPlaceholderImages();
    payLoad.value.imageFile = { filesId: placeholderResponse.fileHeaderId };
    payLoad.value.appLogoFile = { filesId: placeholderResponse.fileLogoId };
    try {
      for (let i = 0; i < quantity; i++) {
        const products = await createProduct(user.value.companyId, payLoad.value);
        toast.success("New Product was successfully added.");
        emitter.emit("newProductAdded", products);
      }
    } catch (error) {
      toast.error("Something went wrong. Product was not added.");
      console.error("Error adding new product: " + error);
    }
  }

  function paymentResponse(status: string) {
    if (status === "success") {
      toast.success("The number of products has been updated for your subscription");
      //emitter.emit("subscription-modal");
    } else {
      toast.error("There was an issue with update number of products");
    }
  }
</script>

<style scoped>
.go-summary {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
  /* padding-left: 2rem; */
}

.spinner {
  padding: 0;
  width: 7rem;
  text-align: center;
  overflow: hidden;
  background-image: none;
}

.spinner-button,
.spinner-button:active
.spinner-button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}

.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.current-payment {
  font-size: 18px;
}

.default-payment {
  font-size: 12px;
}

.btn.btn-success {
  transition-duration: 0.75s;
  animation-name: colorTransition;
}

@keyframes colorTransition {
  from {
    background-color: #3679e8 !important;
  }

  to {
    background-color: #38c172 !important;
  }
}
</style>
