<template>
  <authentic-modal ref="inviteUserModal" id="invite-user-modal" :title="modalTitle" :on-submit="submit" size="md">
    <div ref="form" class="form ps-4">
      <div class="mb-3 pe-4">
        <label for="user-name">Email</label>
        <input type="text" class="form-control mt-2" id="user-name" v-model="newUserEmail.value.value"
          aria-describedby="company-name-live-feedback" :class="{ 'is-invalid': newUserEmail.errorMessage.value }" />
        <div id="company-name-live-feedback" class="invalid-feedback">
          {{ newUserEmail.errorMessage.value }}
        </div>
      </div>
      <div class="mb-3 w-50">
        <label for="user-role">Role</label>
        <select id="user-role" v-model="selectedRole" class="form-control form-select mt-2"
          aria-placeholder="Select role...">
          <option v-for="option in roleOptions" :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>
    </div>

    <template v-slot:modal-footer>
      <div class="btn-group pe-4">
        <button type="button" class="btn btn-outline-secondary btn-sm rounded-pill ms-2" @click.prevent="cancel">
          Cancel
        </button>
        <button type="button" class="btn btn-primary btn-sm rounded-pill ms-2" :disabled="!meta.valid"
          @click.prevent="submit">
          Send
        </button>
      </div>
    </template>
  </authentic-modal>
</template>

<script setup lang="ts">
  import AuthenticModal from "../shared/AuthenticModal.vue";
  import User from "../../models/User";
  import { createUser } from "../../services/AdminService";
  import { useToast } from "vue-toastification";

  import { useField, useForm } from "vee-validate";
  import { object, string } from "yup";
  import { computed, inject, markRaw, onBeforeUnmount, onMounted, ref } from "vue";
  import { useStore } from "vuex";

  const emitter: any = inject("emitter");
  const $store = useStore();
  const toast = useToast();

  const inviteUserModal = ref<typeof AuthenticModal>();

  const user = computed(() => {
    return $store.getters.authUser;
  });
  const company = computed(() => {
    return $store.getters.company;
  });

  const validationSchema = markRaw(
    object({
      newUserEmail: string()
        .email("please provide a valid email")
        .required("user email is required")
        .trim(),
    })
  );
  const { validate, errors, setErrors, meta } = useForm({ validationSchema });
  const newUserEmail = useField("newUserEmail", validationSchema);

  let newUser: Partial<User> = {};
  const modalTitle = "Invite user";

  let selectedRole = "User";
  const roleOptions = [
    { value: "User", text: "User" },
    { value: "Admin", text: "Admin" },
  ];

  onMounted(async () => {
    emitter.on("invite-user", () => {
      inviteUserModal.value?.showModal();
    });
  });

  async function submit() {
    newUser.role = selectedRole;
    newUser.status = "Active";
    newUser.email = newUserEmail.value.value as string;
    try {
      const response = await createUser(company.value.companyId, newUser);
      if (response === "success") {
        inviteUserModal.value?.hideModal();
        toast.success(newUser.email + " has been invited to create an account!");
        newUser = {};
        emitter.emit("newuserinvite");
      } else {
        toast.error("User invite failed, please try again");
      }
    } catch {
      toast.error("User invite failed, please try again");
    }
  }
  async function cancel() {
    inviteUserModal.value?.hideModal();
  }

  onBeforeUnmount(() => {
    emitter.off("invite-user");
  });

</script>

<style scoped></style>
