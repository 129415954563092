<template>
  <div class="avatar-container">
    <img
      v-if="user.avatar"
      id="avpic"
      :src="avatarUrl"
      class="profile-avatar img-fluid d-block"
    />

    <div class="dropdown icon-dropdown">
      <button
        class="btn btn-link text-decoration-none"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-plus-circle-fill" style="font-size:1.25rem"></i>
      </button>

      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li v-for="avatar in avatars" :key="avatar.value">
          <a
            class="dropdown-item"
            href="#"
            @click="updateAvatar(avatar.value)"
          >
            <img
              v-if="avatar.value"
              id="avpic"
              :src="avatarValue(avatar.value)"
              class="pe-2"
            />
            {{ avatar.text }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  const store = useStore();
  const user = computed(() => store.getters.authUser);

  interface Avatar {
    text: string;
    value: string;
  }

  const avatars: Avatar[] = [
    { text: 'Bot 1', value: 'bot1.jpg' },
    { text: 'Bot 2', value: 'bot2.jpg' },
    { text: 'Bot 3', value: 'bot3.jpg' },
    { text: 'Bot 4', value: 'bot4.jpg' },
  ];

  const avatarUrl = computed(() => {
    return new URL(`/src/assets/images/avatars/${user.value.avatar}`, import.meta.url).href;
  });

  function updateAvatar(avatar: string) {
    user.value.avatar = avatar;
  }

  function avatarValue(value: string) {
    return new URL(`/src/assets/images/avatars/sm_${value}`, import.meta.url).href;
  }
  </script>

  <style scoped>
  .avatar-container {
    position: relative;
  }
  .profile-avatar {
    border: 0;
    border-radius: 10px;
    width: 150px;
  }
  .icon-dropdown {
    position: absolute;
    top: -22px;
    left: -22px;
  }
  :deep .dropdown-item {
    font-family: "proximanova-regular", sans-serif;
  }
  :deep .btn:focus,
  .btn.focus {
    box-shadow: none;
  }
</style>
