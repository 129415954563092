<template>
  <div class="card pro-card">
    <div class="row">
      <div class="col-7">
        <p class="subscription-header text-center">Pro</p>
      </div>
    </div>

    <div v-if="props.modalType === 'billing'" class="row" style="margin-bottom: -1.5rem;">
      <div class="col">
        <span class="dollar">$</span>
        <span class="subscription-price">{{ pro.price }}</span>
        <span class="subscription-price-text">/ {{ $filters.capitalizeFirst(pro.interval as string) }}</span>
      </div>
    </div>

    <div v-if="props.modalType !== 'billing'" class="row ps-4 pt-2">
      <div class="col">
        <p class="plan-description">For brands needing more product-specific experiences and improved analytics</p>
      </div>
    </div>

    <div class="row ps-2 pt-2 pb-4">
      <div class="col">
        <ul class="list-group" style="line-height: 28px;">
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Everything included in Basic Plus...</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Membership and Association Badges</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Annual Report Linking</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">3 Product Experiences and Codes</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Up to 5 Sections per Product</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Premium Analytics</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Concierge Onboarding</span>
          </li>
          <li class="list-group-item pt-0 icon-container">
            <i class="bi bi-check2 icon"></i><span class="ps-2">Priority Email Support</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row px-3 pb-3 align-items-end align-self-center row-bottom" v-if="props.showSubmit">
      <div class="col">
        <payment-choices :subscription-type="(pro as any)"> </payment-choices>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, computed } from "vue";
  import { defineProps } from "vue";
  import Plan from "../../../models/Plan";
  import PaymentChoices from "../PaymentChoices.vue";
  import { useStore } from "vuex";

  const props = defineProps<{
    planDetails?: Plan;
    modalType?: string;
    showSubmit?: boolean;
  }>();

  const store = useStore();

  const plans = computed(() => store.getters.plans);
  const pro = ref<Plan>({});

  onMounted(() => {
    pro.value = plans.value.filter((plan: { name: string; }) => plan.name === "pro")[0];
  });
</script>

<style scoped>
.icon{
  /* color: #3679e8; */
  font-size: 1.5rem;
}

.icon-container{
  display: flex;
  align-items: center;
}

.plan-card {
  border-radius: 0.75rem;
}

.plan-header {
  font-size: 32px;
  font-family: "proximanova-medium", sans-serif;
}

.card {
  border: 2px solid #898989;
  border-radius: 0.75rem;
}

@media (min-width: 1200px) {
  .pro-card {
    height: 100%;
  }
}

@media (max-width: 991px) {
  .pro-card {
    height: 100%;
  }
}

.card-body {
  padding-top: 0px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
}

.list-group-item {
  border: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 0.5rem;
}

.list-group {
  font-family: "proximanova-medium", sans-serif;
  font-size: 16px;
}

.row-bottom {
  flex: 1 0 0;
}

.subscription-price {
  font-size: 64px;
  font-family: "proximanova-medium", sans-serif;
  padding-right: 0px;
  padding-left: 3.5rem;
}

.plan-description {
  font-size: 16px;
  font-family: "proximanova-medium", sans-serif;
}

.dollar {
  font-size: 2rem;
  vertical-align: top;
  position: absolute;
  top: 4rem;
  padding-left: 2rem;
}

.subscription-price-text {
  font-size: 20px;
  font-family: "proximanova-regular", sans-serif;
}

.subscription-header {
  font-family: "proximanova-regular", sans-serif;
  font-size: 22px;
  background-color: #14a899;
  color: white;
  border-bottom-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
</style>
