<template>
  <div class="pb-2 px-4 container-fluid d-flex h-100 flex-column">
    <div class="row g-0">
      <div class="col">
        <h5 class="current">Current plan</h5>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <div class="row g-1">
          <p class="card-text subscription-plan">{{ billingDetails.subscriptionName }}</p>
        </div>
        <div class="row g-0 pb-0">
          <p class="card-text subscription-price">${{ billingDetails.planPrice }} /
            {{ billingDetails.paymentInterval }}</p>
        </div>
        <div class="row g-0">
          <p class="card-text subscription-renewal">Your plan renews on {{ billingDetails.renewalDate }}</p>
        </div>
      </div>
      <div class="col text-end">
          <button class="btn btn-outline-danger btn-sm rounded-pill mx-2" @click="cancelSubscription"
          :disabled="disableCancel">Cancel current plan</button>
      </div>
    </div>

    <div class="row pt-4" no-gutters>
      <div class="col">
        <h5 class="current">Cancellation</h5>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card-text">
          <span class="warning-text">Warning:</span> Selecting Cancel current
          plan above will terminate your current plan with Authentic Labs.
          Access to the Real&reg; Brand Dashboard will still be available until
          your renewal date after that you will not be able to edit or publish
          content. You will be able to resume your plan anytime up to the
          cancellation date.
        </div>
      </div>
    </div>

    <div class="row flex-grow-1 align-items-end text-end row-bottom pb-3">
      <div class="col align-right">
        <div class="btn-group" role="group">
          <button class="btn btn-primary btn-sm rounded-pill ms-2 action-button" type="button" @click="summary">Back to
            summary</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { Company } from "@authentic-labs/vue-component-library";
  import Billing from "../../models/Billing";
  import { cancelPlan } from "../../services/PaymentService";
  import { useToast } from "vue-toastification";
  import { useStore } from "vuex";
  import { computed, ref, inject } from "vue";

  const $store = useStore();
  const toast = useToast();
  const emitter: any = inject("emitter");

  const prop = defineProps<{
    billingDetails: Billing;
  }>();

  const company = computed((): Company => {
    return $store.getters.company;
  });

  const disableCancel = ref(false);

  function summary() {
    emitter.emit("billing-summary");
  }

  async function cancelSubscription() {
    disableCancel.value = true;
    const response = await cancelPlan();
    if (response === "success") {
      toast.success("Your plan was successfully cancelled");
      emitter.emit("billing-summary", "refresh");
      disableCancel.value = false;
    } else {
      toast.error("A problem occurred when cancelling your account");
    }
  }
</script>

<style scoped>
.go-summary {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}

.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.current-plan {
  font-size: 18px;
}

.warning-text {
  color: red;
  font-size: 20px;
  font-family: "proximanova-bold", sans-serif;
}
</style>
