<template>
  <div class="pb-2 container-fluid d-flex h-100 flex-column">
    <div class="row g-0">
      <div class="col">
        <h5 class="current">Current plan</h5>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <div class="row g-0">
          <p class="card-text subscription-plan">{{
            updateBillingDetails.subscriptionName
            }}</p>
        </div>
        <div class="row g-0 pb-0" no-gutters>
          <p class="card-text subscription-price">${{ updateBillingDetails.planPrice }} /
            {{ updateBillingDetails.paymentInterval }}</p>
        </div>
        <div class="row g-0">
          <p class="card-text subscription-renewal">Your plan renews on
            {{ updateBillingDetails.renewalDate }}</p>
        </div>
      </div>
      <div class="col text-end update-quantity">
        <button class="btn btn-primary btn-sm rounded-pill me-3" @click="updateQuantity">Update product
          quantity</button>
      </div>
    </div>

    <div class="row g-0 pt-4">
      <div class="col">
        <h5 class="current">Plans available</h5>
      </div>
    </div>

    <div class="row pt-4 g-0 current" v-if="showPlans && basic">
      <div class="col">
        <div class="row g-0">
          <div class="col-4">
            <p class="card-text subscription-plan pe-4">{{
              $filters.capitalizeFirst(basic.name as string)
              }}</p>
          </div>
          <div class="col">
            <p class="card-text subscription-price" v-if="displayFreq === 'monthly'">${{ basic.price }} /
              Month</p>
          </div>
          <div class="text-end col-auto">
            <button v-if="updateBillingDetails.subscriptionName !== 'Basic'"
              class="btn btn-primary btn-sm rounded-pill select-button" @click="upgradePlan('basic')">Select</button>
          </div>
        </div>
      </div>

    </div>

    <div class="row pt-3 g-0 current" v-if="showPlans && pro">
      <div class="col">
        <div class="row g-0">
          <div class="col-4">
            <p class="card-text subscription-plan pe-4">{{ $filters.capitalizeFirst(pro.name as string) }}</p>
          </div>
          <div class="col">
            <p class="card-text subscription-price" v-if="displayFreq === 'monthly'">${{ pro.price }} /
              Month</p>
          </div>
          <div class="text-end col-auto">
            <button type="button" class="btn btn-primary btn-sm rounded-pill select-button" @click="upgradePlan('pro')"
              v-if="updateBillingDetails.subscriptionName !== 'Pro'">Select</button>
          </div>
        </div>
      </div>

    </div>

    <div class="row pt-3 g-0 current" v-if="showPlans && business">
      <div class="col">
        <div class="row g-0">
          <div class="col-4">
            <p v-if="business" class="card-text subscription-plan pe-4">{{
              $filters.capitalizeFirst(business.name as string)
              }}</p>
          </div>
          <div class="col">
            <p class="card-text subscription-price" v-if="displayFreq === 'monthly'">${{ business.price }} /
              Month</p>
          </div>
          <div class="text-end col-auto">
            <button type="button" class="btn btn-primary btn-sm rounded-pill select-button"
              @click="upgradePlan('business')"
              v-if="updateBillingDetails.subscriptionName !== 'Business'">Select</button>
          </div>
        </div>
      </div>

    </div>

    <div class="row g-0 text-end row-bottom py-4">
      <div class="col align-right px-0">
        <div class="btn-group" role="group">
          <button class="btn btn-primary btn-sm rounded-pill" type="button" @click="summary">Back to
            summary</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import Billing from "../../models/Billing";
  import Plan from "../../models/Plan";
  import {
    updateAdditionalProducts,
  } from "@/services/PaymentService";
  import { defineProps, defineEmits, ref, computed, inject, onBeforeMount, onMounted } from "vue";
  import { useStore } from "vuex";

  const $store = useStore();
  const emitter: any = inject("emitter");

  const props = defineProps<{
    billingDetails: Billing;
  }>();

  const plans = computed((): Plan [] => {
    return $store.getters.plans;
  })

  const defaultPayment = computed(() => {
    let paymentMethod: any = [];
    const found = props.billingDetails.paymentMethods.find((element: any) => {
      element.default === true;
      paymentMethod = element;
    });
    return paymentMethod;
  });

  const updateBillingDetails = ref<Partial<Billing>>({});
  const showPlans = ref(false);
  const basic = ref<Partial<Plan>>({});
  const pro = ref<Partial<Plan>>({});
  const business = ref<Partial<Plan>>({});
  const enterprise = ref<Partial<Plan>>({});

  const freqButtons = [
    { caption: "Monthly", state: false },
    { caption: "Yearly", state: false },
  ];

  const displayFreq = ref("monthly");

  function summary() {
    emitter.emit("billing-summary");
  }

  function selectedFreq(idx: number) {
    freqButtons.forEach((b, index) => (b.state = idx === index));
    if (idx === 0) {
      displayFreq.value = "monthly";
    } else {
      displayFreq.value = "yearly";
    }
  }

  onBeforeMount(() => {
    $store.dispatch("getPlans");
  })

  onMounted(() => {
    basic.value = plans.value.filter((plan: Plan) => plan.name === "basic")[0];
    pro.value = plans.value.filter((plan) => plan.name === "pro")[0];
    business.value = plans.value.filter((plan) => plan.name === "business")[0];
    enterprise.value = plans.value.filter(
      (plan) => plan.name === "enterprise"
    )[0];

    selectedFreq(0);
    if (props.billingDetails.paymentInterval === "year") {
      selectedFreq(1);
    }
    if (
      props.billingDetails.paymentInterval === "month" &&
      props.billingDetails.subscriptionName === "Pro"
    ) {
      selectedFreq(1);
    }
    setVisiblePlans();
    updateBillingDetails.value = props.billingDetails;
    selectedFreq(0); 
  });

  function setVisiblePlans() {
    if (
      props.billingDetails.subscriptionName !== "Pro" &&
      props.billingDetails.paymentInterval !== "year"
    ) {
      showPlans.value = true;
    }
    if (
      props.billingDetails.subscriptionName === "Pro" &&
      props.billingDetails.paymentInterval === "month"
    ) {
      showPlans.value = true;
    }
    if (
      props.billingDetails.subscriptionName === "Pro" &&
      props.billingDetails.paymentInterval === "month" &&
      props.billingDetails.additionalProducts > 0
    ) {
      showPlans.value = false;
    }
    if (
      props.billingDetails.subscriptionName === "Signature" &&
      props.billingDetails.paymentInterval === "year"
    ) {
      showPlans.value = true;
    }
    showPlans.value = true; 
  }

  async function upgradePlan(plan: string) {
    let numberofProd = 0;
    let newPlan: Partial<Plan> = {};
    switch (plan) {
      case "basic":
        newPlan = basic.value;
        numberofProd = basic.value.productNumber!;
        break;
      case "pro":
        newPlan = pro.value;
        numberofProd = pro.value.productNumber!;
        break;
      case "business":
        newPlan = business.value;
        numberofProd = business.value.productNumber!;
        break;
      case "enterprise":
        newPlan = enterprise.value;
        numberofProd = enterprise.value.productNumber!;
        break;
    }
    emitter.emit("billing-modal-close");
    emitter.emit("payment-modal", {planType: newPlan, upgrade: true});
  }

  async function removeAdditionalProducts(count: number, plan: string) {
    const priceId = import.meta.env.VITE_APP_ADDITIONAL_PRODUCT;
    const paymentDetails = {
      paymentMethod: defaultPayment.value.id,
      priceId: priceId,
      quantity: count,
      subscriptionType: plan,
      currentAdditionalProducts: props.billingDetails.additionalProducts,
    };
    const subscribeResponse = await updateAdditionalProducts(paymentDetails);
  }

  function updateQuantity() {
    emitter.emit("billing-update-quantity", {productList: false, fromArchive: false});
  }
</script>

<style scoped>
.go-summary {
  text-decoration: none;
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
}

.update-quantity {
  position: absolute;
  right: 0;
  top: 40px;
}

.current {
  border-bottom: 1px solid lightgray;
  padding-bottom: 0.5rem;
}

.subscription-plan {
  font-family: "proximanova-bold", sans-serif;
  font-size: 20px;
}

.subscription-price {
  font-family: "proximanova-regular", sans-serif;
  font-size: 20px;
}

.current-plan {
  font-size: 18px;
}

.update-disabled {
  background-color: green;
}

.select-button {
  width: 80px;
}

.select-button-col {
  position: absolute;
  right: 0;
}

@media screen and (max-width: 576px) {
  .subscription-price[data-v-02a0f5e3] {
    font-size: 16px;
  }

  .subscription-plan[data-v-02a0f5e3] {
    font-size: 16px;
  }
}
</style>
