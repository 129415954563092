mobile working somewhat

<template>
  <div style="background: white">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary navbar-custom">
      <div class="container-lg">
        <a class="navbar-brand" href="#">
          <router-link :to="{ name: 'signature', query: { companyId: user.companyId } }">
            <img :src="getReal" alt="Real Logo" height="30" @click="refresh()" />
          </router-link>
        </a>
        <!-- Navbar toggler for smaller screens with offcanvas target -->
        <button class="navbar-toggler" type="button" @click.stop="toggleOffcanvas" aria-controls="sidebar-right"
          aria-expanded="false" aria-label="Toggle navigation" v-if="isLoggedIn">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="nav-collapse">
          <ul class="navbar-nav ms-auto" v-if="user.name">
            <li class="nav-item dropdown" v-if="isLoggedIn">
              <a class="nav-link dropdown-toggle nav-link-custom" href="#" id="userDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img alt="Avatar" :src="avatarUrl" class="rounded-circle" />
                <span class="icon-spacing">{{ user.name }}</span>
                <i class="bi bi-chevron-down icon-spacing"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                <li v-if="user.role === 'Superadmin'">
                  <a class="dropdown-item user-dropdown" @click="companyAdmin">
                    <i class="bi bi-gear icon"></i>Super admin
                  </a>
                </li>
                <li>
                  <a class="dropdown-item user-dropdown" @click="editProfile">
                    <i class="bi bi-person-circle icon"></i>Edit profile
                  </a>
                </li>
                <li v-if="user.role === 'Admin' || user.role === 'Superadmin'">
                  <a class="dropdown-item user-dropdown" @click="billingSummary">
                    <div class="icon-billing icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 30 30">
                        <g id="Group_329" data-name="Group 329" transform="translate(-25.906 -18.412)">
                          <g id="Group_331" data-name="Group 331">
                            <path id="Path_3340" data-name="Path 3340" d="M31.574,48.312a12,12,0,0,1,12-20.557"
                              transform="translate(0 -4.786)" fill="none" stroke="#999" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2" />
                            <path id="Path_3341" data-name="Path 3341" d="M59.8,36.923V32.89h3.978"
                              transform="translate(110.88 37.487) rotate(163)" fill="none" stroke="#999"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                          </g>
                          <g id="Group_332" data-name="Group 332" transform="translate(78.986 68.187) rotate(-180)">
                            <path id="Path_3340-2" data-name="Path 3340" d="M31.574,48.312a12,12,0,0,1,12-20.557"
                              transform="translate(0 -4.786)" fill="none" stroke="#999" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="2" />
                            <path id="Path_3341-2" data-name="Path 3341" d="M59.8,36.923V32.89h3.978"
                              transform="translate(110.88 37.487) rotate(163)" fill="none" stroke="#999"
                              stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                          </g>
                        </g>
                      </svg>
                    </div> Billing
                  </a>
                </li>
                <li v-if="user.role === 'Superadmin' || user.role === 'Admin'">
                  <a class="dropdown-item user-dropdown" @click="companyDetails">
                    <i class="bi bi-briefcase icon"></i> Company details
                  </a>
                </li>
                <li v-if="user.role === 'Superadmin' || user.role === 'Admin'">
                  <a class="dropdown-item user-dropdown" @click="userManagement">
                    <i class="bi bi-list-task icon"></i> User management
                  </a>
                </li>
                <li>
                  <a class="dropdown-item user-dropdown" @click="logoutUser">
                    <i class="bi bi-x-circle icon"></i> Log out
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Sidebar offcanvas for mobile with custom fixed position styling -->
    <div ref="canvasMenu" class="offcanvas offcanvas-end d-lg-none custom-sidebar-offcanvas" tabindex="-1"
      id="sidebar-right">
      <div class="offcanvas-body p-0">
        <div class="container-lg custom-sidebar">
          <a class="navbar-brand d-md-block d-lg-none brand-mobile-sidebar" href="#">
            <router-link :to="{ name: 'signature', query: { companyId: user.companyId } }">
              <img :src="getReal" alt="Real Logo" height="30" @click="refresh()" />
            </router-link>
          </a>
          <button class="navbar-toggler close-sidebar" type="button" style="overflow-anchor: none;"
            @click.stop="toggleOffcanvas">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <div class="card links">
          <a class="p-2 side-menu-links" v-if="user.role === 'Superadmin'" @click="companyAdmin"
            data-bs-dismiss="offcanvas">
            <h4 class="h4-b text-center pe-3">Super Admin</h4>
          </a>
          <a class="p-2 side-menu-links" @click="editProfile" data-bs-dismiss="offcanvas">
            <h4 class="h4-b text-center pe-3">Edit profile</h4>
          </a>
          <a class="p-2 side-menu-links" v-if="user.role === 'Admin' || 'Superadmin'" @click="billingSummary"
            data-bs-dismiss="offcanvas">
            <h4 class="h4-b text-center pe-3">Billing</h4>
          </a>
          <a class="p-2 side-menu-links" v-if="user.role === 'Superadmin' || user.role === 'Admin'"
            @click="companyDetails" data-bs-dismiss="offcanvas">
            <h4 class="h4-b text-center pe-3">Company detail</h4>
          </a>
          <a class="p-2 side-menu-links bottom-border-off" v-if="user.role === 'Superadmin' || user.role === 'Admin'"
            @click="userManagement" data-bs-dismiss="offcanvas">
            <h4 class="h4-b text-center pe-3">User management</h4>
          </a>
          <a class="p-2 side-menu-links bottom-border-off" @click="logoutUser" data-bs-dismiss="offcanvas">
            <h4 class="h4-b text-center pe-3">Log out</h4>
          </a>
        </div>
      </div>
    </div>

    <CompanyDetails />
    <CompanyUsers v-if="user.name" />
    <UserProfile />
    <RealSubscriptions />
    <PaymentModal />
    <BillingModal />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import UserProfile from '../components/auth/UserProfile.vue';
import CompanyDetails from '../components/accounting/CompanyDetails.vue';
import CompanyUsers from '../components/accounting/CompanyUsers.vue';
import PaymentModal from '../components/accounting/PaymentModal.vue';
import BillingModal from '../components/accounting/BillingModal.vue';
import RealSubscriptions from '../components/shared/RealSubscriptions.vue';
import { Offcanvas } from 'bootstrap';

import { logout } from '../services/AuthService';
import defaultAvi from '@/assets/images/avatars/sm_bot1.jpg';

const store = useStore();
const router = useRouter();
const emitter: any = inject('emitter');

const isLoggedIn = computed(() => store.getters.isLoggedIn);
const user = computed(() => store.getters.authUser);
const company = computed(() => store.getters.company);
const subscription = computed(() => store.getters.subscription);
let offcanvasInstance: Offcanvas | null = null;

const canvasMenu = ref<HTMLElement | null>(null);

const avatarUrl = computed(() => {
  return user.value.avatar === null
    ? new URL('/src/assets/images/avatars/sm_bot1.jpg', import.meta.url).href
    : new URL(`/src/assets/images/avatars/sm_${user.value.avatar}`, import.meta.url).href;
});

const getReal = new URL('@/assets/images/app/RealLogo.svg', import.meta.url).href;
const sidebarRight = ref<HTMLElement | null>(null);

onMounted(() => {
  getAnalytics();
});

function editProfile() {
  emitter.emit('user-profile');
}

function companyAdmin() {
  router.push({ name: 'companyList' });
}

function companyDetails() {
  emitter.emit('company-details');
}

function userManagement() {
  emitter.emit('user-management');
}

async function billingSummary() {
  if (user.value.role === 'Admin' && !company.value.demo) {
    if (company.value.type !== 'free' && company.value.type !== null && company.value.status === 'Active' && subscription.value.isActive) {
      emitter.emit('billing-modal');
    } else {
      emitter.emit('subscription-modal');
    }
  } else if (user.value.role === 'Superadmin') {
    emitter.emit('subscription-modal');
  }
}

async function getAnalytics() {
    try {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
      const startDate = thirtyDaysAgo.toISOString().split('T')[0];
      const endDate = new Date().toISOString().split('T')[0];
      store.commit("resetAnalyticsData", {});
      await store.dispatch("getAnalyticsData", {companyId: company.value.companyId, dateRange: {startDate: startDate, endDate: endDate}});
    } catch (error) {
      console.log(error, " Error retrieving company analytics data");
    }
  }


function refresh() {
  store.commit('updatePreview', 'company');
  if (user.value.role === 'Superadmin' && company.value.companyId !== user.value.companyId) {
    store.commit('resetCompany');
    store.dispatch('getCompany', user.value.companyId);
    store.dispatch('getSubscriptionStatus', { user: user.value });
  }
}

function toggleOffcanvas() {
  const offcanvasElement = canvasMenu.value;
  if (!offcanvasElement) return;
  const bsOffcanvas = Offcanvas.getInstance(offcanvasElement) || new Offcanvas(offcanvasElement);
  if ((bsOffcanvas as any)._isShown) {
    bsOffcanvas.hide();
  } else {
    bsOffcanvas.show();
  }
}

onBeforeUnmount(() => {
  if (offcanvasInstance) {
    offcanvasInstance.dispose();
    offcanvasInstance = null;
  }
});

async function logoutUser() {
  await logout();
  store.commit('login', false);
  store.commit('authUser', {});
  store.commit('resetCompany', {});
  store.commit('resetProducts', {});
  store.commit('setSubscription', {});
  store.commit('setSessionStart', '');
  store.commit('setGs1Settings', {});
  router.push({ name: 'login' });
}
</script>

<style scoped lang="scss">
@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1450px;
  }
}

.custom-sidebar-offcanvas {
  width: 80%;
  max-width: none;
  height: 100vh;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 1050;
  background-color: white;
}

.navbar-custom {
  height: 50px;
  padding: .5rem 1rem;
}

:deep .dropdown-item:hover,
.dropdown-item:focus {
  font-family: "proximanova-bold", sans-serif;
  color: #898989;
  text-decoration: none;
  background-color: #e9ecef;
}

:deep .dropdown-item {
  font-family: "proximanova-regular", sans-serif;
  color: #898989;
  cursor: pointer;
}

/* Adjust the custom offcanvas styling for a true sidebar effect */
.custom-sidebar-offcanvas {
  width: 80%;
  /* Adjust as needed */
  max-width: none;
  height: 100vh;
  right: 0;
  /* Ensures it opens from the right */
  position: fixed;
  /* Fixed position to prevent overlap issues */
  top: 0;
  z-index: 1050;
  /* Ensures it's above other elements */
  background-color: white;
  /* Or any desired background color */
}

.icon-spacing {
  margin-left: 0.5rem;
}

/* Other existing styles remain unchanged */
@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1450px;
  }
}

.navbar-custom {
  height: 50px;
  padding: .5rem 1rem;
}

:deep .dropdown-item:hover,
.dropdown-item:focus {
  font-family: "proximanova-bold", sans-serif;
  /* font-weight: bold; */
  color: #898989;
  text-decoration: none;
  background-color: #e9ecef;
}

:deep .dropdown-item {
  font-family: "proximanova-regular", sans-serif;
  color: #898989;
  cursor: pointer;
}

.ani-menu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  transform: translateY(-3em);
  z-index: -1;
  transition: all 0.3s ease 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

:deep .dropdown:focus .ani-menu,
.dropdown:focus-within .ani-menu,
.dropdown:hover .ani-menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0);
  transition-delay: 0s, 0s, 0.3s;
}


.icon-spacing {
  margin-left: 0.5rem;
}

.icon {
  color: #898989;
  margin-right: 0.5rem;
  margin-top: 1px;
  font-size: 1.2rem;
}

:deep(.user-dropdown) {
  display: flex;
  align-items: center;
}

.icon-billing {
  margin-bottom: 3px;
}

.icon-stack {
  position: relative;
  width: 20px;
  display: flex;
  margin-top: 2px;
}

.icon-stack .icon {
  position: absolute;
  width: 20px
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  line-height: 60px;
  background-color: #f9f9f9;
  display: flex;
}

.brand-mobile-sidebar {
  padding-top: 0.85rem;
  padding-left: 2rem;
}

.close-sidebar {
  position: absolute;
  right: 3rem;
  top: 0.6rem;
}

.brand-mobile {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 1.3rem;
}

.mobile-toggle {
  position: absolute;
  right: 1rem;
  top: 1.72rem;
  padding-bottom: 10px;
}

.side-menu-links {
  height: 45px;
  text-decoration: none;
  cursor: pointer;
}

.card {
  border: none;
}

.links {
  padding-top: 5rem;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.32rem;
  padding-bottom: 0.32rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap;
}

.custom-sidebar {
  background-color: #3679e8;
  height: 50px;
}

:deep .b-sidebar {
  border-bottom-left-radius: 12px !important;
  min-width: 100%;
  z-index: 1;
  top: -3px;
  background-color: #ffffff !important;
}

:deep .b-sidebar-header {
  background-color: #3679e8;
  height: 54px;
  align-items: flex-end !important;
}

:deep .b-sidebar.b-sidebar-right>.b-sidebar-header .close {
  margin-right: auto;
  position: absolute;
  right: 20px;
  color: black !important;
  opacity: 1;
}

:deep .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

:deep .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2.5' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

@media all and (min-width: 992px) {
  :deep .navbar .nav-item .dropdown-menu {
    display: none;
  }

  :deep .navbar .nav-item:hover .nav-link {
    color: #fff;
  }

  :deep .navbar .nav-item:hover .dropdown-menu {
    display: block;
    line-height: 22px;
  }

  :deep .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

:deep .dropdown-toggle::after {
  display: none;
}
</style>
